import React from 'react';
import PropTypes from 'prop-types';
import './Toast.scss';

class Toast extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            show: this.props.show,
            headerIcon: this.props.headerIcon
        };

        this.closeButtonHandle = this.closeButtonHandle.bind(this);

    }

    componentDidUpdate(prevProps)
    {
        if(prevProps.show !== this.props.show)
        {
            this.setState({show: this.props.show});
        }
        if(prevProps.headerIcon !== this.props.headerIcon)
        {
            this.setState({headerIcon});
        }
    }

    closeButtonHandle()
    {
        this.setState({show: false});
    }

    render()
    {
        return  <div id="liveToast" className={`toast position-relative ${this.state.show ? 'show' : 'hide'}`} role="alert" aria-live="assertive" aria-atomic="true">
        <div className={`toast-header ${(this.props.theme === 'red' && 'toast-header-red') || (this.props.theme === 'green' && 'toast-header-green') || (this.props.theme === 'blue' && 'toast-header-blue')}`}>
            <strong className="me-auto">{this.state.headerIcon ? <i class={this.state.headerIcon}></i>: ""}{this.props.headerText}</strong>
            <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" onClick={this.closeButtonHandle}></button>
        </div>
        <div className={`toast-body ${(this.props.theme === 'red' && 'toast-body-red') || (this.props.theme === 'green' && 'toast-body-green') || (this.props.theme === 'blue' && 'toast-body-blue')}`}>
            <span className="">{this.props.bodyText}</span>
        </div>
        </div>;
    }
}

Toast.propTypes = {
    theme: PropTypes.string,
    headerText: PropTypes.string,
    headerIcon: PropTypes.string,
    bodyText: PropTypes.string,
    show: PropTypes.bool
};

export default Toast;