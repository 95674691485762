import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import thirdPartyService from '../../_services/thirdParty.service';
import { useState } from 'react';



function TemporaryRoute({ component: Component, ...rest })
{
    const [routeExistsState, setRouteExists] = useState(false);

    const routeExists = (hash) => {
        thirdPartyService.routeExists(hash)
        .then((response) => {
            setRouteExists(response.data);
        });
    }

    useEffect(() => {
        console.log(routeExistsState);
    })

    return <Route {...rest} render={routeProps => (
            routeExists(routeProps.match.params.hash),
            (routeExistsState === true ? <Component {...routeProps} />
             :  <div>Diese Route existiert nicht mehr</div> )
    )} />
}

TemporaryRoute.propTypes = {
    component: PropTypes.any,
    location: PropTypes.any
};

export default TemporaryRoute;