import { SHOW_EDITCARRIERDATA_MODAL, HIDE_EDITCARRIERDATA_MODAL, CHANGE_EDITCARRIERDATA, CHANGE_SELECTEDCLIENTID} from '../actionTypes';

const initalState = {
    modalOpen: false,
    modalProps: {},
    carrierDataStateRedux: {},
    selectedClientId: -1
};

const carrierDataModal = (state = initalState, action) => {
    switch (action.type) {
        case SHOW_EDITCARRIERDATA_MODAL:
            {
                return {...state, modalOpen: true   };
            }
        case HIDE_EDITCARRIERDATA_MODAL:
            {
            return { ...state, modalOpen: false };
            }
        case CHANGE_EDITCARRIERDATA:
            {
                return { ...state, carrierDataStateRedux: action.payload }
            }
        case CHANGE_SELECTEDCLIENTID:
            {
                return { ...state, selectedClientId: action.payload}
            }
        default:
            return state;
    }
};

export default carrierDataModal;