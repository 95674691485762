import { SET_MODEL } from './actionTypes';

const initialCanvasState = {
    model: null
};


export default function(state = initialCanvasState, action) {
    switch (action.type) {
        case SET_MODEL: {
            const model = action.payload.model;
            const newState = {...state, model: model};
            return newState;
        }
        default:
            return state;
    }
}