import {SET_USERNAME} from '../actionTypes';

const initalState = {
    username: 'test'
};

const user = (state = initalState, action) => {
    switch (action.type) {
        case SET_USERNAME:
            {
                return { username: action.payload.username };
            }
        default:
            return state;
    }
};

export default user;