import React from 'react';
import ApiAccess from '../../Tools/ApiAccess';
import Cookie from '../../Tools/Cookie';
import Properties from '../../Tools/Properties';
import Toast from '../../standardComponents/Toast/Toast';
import authService from '../../_services/auth.service';
import './OneWayPassword.scss';


class OneWayPassword extends React.Component
{
    constructor(props)
    {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleLoginButton = this.handleLoginButton.bind(this);
        this.sendToApi = this.sendToApi.bind(this);
        this.formValidation = this.formValidation.bind(this);
        this.createError = this.createError.bind(this);

        this.apiAccess = new ApiAccess();
        this.cookie = new Cookie();

        this.state = 
        {
            header: '',
            message: '',
            successHeader: 'Gespeichert',
            successMessage: 'Die Daten wurden erfolgreich gespeichert. Die Gültigkeit dieses Links ist nun erloschen',
            showError: false,
            showTimeoutError: false,
            username: null,
            password: null,
            usernameEmpty: false,
            passwordEmpty: false,
            loading: false,
            hash: ""
        };
    }

    componentDidMount()
    {
        document.addEventListener('keypress', (e) => {
            if (e.key === 'Enter') {
              this.handleLoginButton();
            }
        });
    }
    componentWillUnmount()
    {
        //document.removeEventListener('keypress');
    }
    componentDidUpdate(prevProps, prevState)
    {
        if(this.state.username !== prevState.username)
        {
            this.formValidation();
        }
        if(this.state.password !== prevState.password)
        {
            this.formValidation();
        }
        if(prevState.showError !== this.state.showError && this.state.showError)
        {
            this.toastTimeout();
        }
        if(prevState.showSuccess !== this.state.showSuccess && this.state.showSuccess)
        {
            this.toastTimeout();
        }
        if(prevState.showTimeoutError !== this.state.showTimeoutError && this.state.showTimeoutError)
        {
            this.toastTimeout();
        }
        if(prevProps.match.params.hash !== this.props.match.params.hash)
        {
            this.setState({hash: this.props.hash});
        }

    }


    toastTimeout()
    {
        if(this.state.showSuccess == true)
        {
            setTimeout(() => {
                this.setState({showSuccess: false});
              }, 10000);
        }
        if(this.state.showError == true)
        {
            setTimeout(() => {
                this.setState({showError: false});
              }, 10000);
        }
        if(this.state.showTimeoutError == true)
        {
            setTimeout(() => {
                this.setState({showTimeoutError: false});
              }, 2000);
        }
    }

    handleInputChange(event)
    {
        this.setState({[event.target.name]: event.target.value});
    }

    handleLoginButton()
    {
        this.setState({loading: true});
        if((this.state.username !== null && this.state.username !== '') && (this.state.password !== null && this.state.password !== ''))
        {
            this.sendToApi();
        }
        else
        {
            this.formValidation();
        }
    }

    formValidation()
    {
        if(this.state.username === null || this.state.username === '')
        {
            this.setState({usernameEmpty: true});
        }
        else
        {
            this.setState({usernameEmpty: false});
        }
        if(this.state.password === null || this.state.password === '')
        {
            this.setState({passwordEmpty: true});
        }
        else
        {
            this.setState({passwordEmpty: false});
        }
    }

    createError(header, message)
    {
        this.setState({header: header});
        this.setState({message: message});
        this.setState({showError: true});
    }

    async sendToApi()
    {
        var jsonObj = {username: this.state.username, password: this.state.password, databaseSession: this.cookie.getCookie('databaseSession')};
        
        authService.oneWay(this.state.username, this.state.password, this.props.match.params.hash)
        .then((response) => {
            console.log(response);
            if(response.status >= 200 && response.status < 400) {
                this.setState({loading: false});
                this.setState({showSuccess: true});
            }
            else 
            {
                this.setState({header: "Ein Fehler ist aufgetreten"});
                this.setState({message: "Bitte noch einmal probieren oder das Ingenieubüro EBL kontaktieren: support@iebl.de | Tel: 04421 3705279"});
                this.setState({showError: true});
                this.setState({loading: false});
            }
        })
        .catch((error) => {
                this.setState({header: "Ein Fehler ist aufgetreten"});
                this.setState({message: "Bitte noch einmal probieren oder das Ingenieubüro EBL kontaktieren: support@iebl.de | Tel: 04421 3705279"});
                this.setState({showError: true});
                this.setState({loading: false});
        });
    }
    render()
    {
        return <div className="mainElement oneWaySite" id="login">
        <div className="centerbox p-3">
            <div className="position-fixed bottom-0 end-0 p-3">
                <Toast show={this.state.showSuccess} headerText={this.state.successHeader} headerIcon="bi bi-check2" bodyText={this.state.successMessage} theme="green"/>
                <Toast show={this.state.showError} headerText={this.state.header} headerIcon="bi bi-x-octagon-fill" bodyText={this.state.message} theme="red"/>
                <Toast show={this.state.showTimeoutError} headerText="Timeout" headerIcon="bi bi-x-octagon-fill" bodyText="Bitte Verbindung überprüfen" theme="red"/>
            </div>
            <strong>Passwort speichern</strong><br />
            <small>Passwort für Drittanbieter Software (Freiwillig-Ja, Ein Jahr Freiwillig) hinterlegen</small><br />
            <form>
                <div className="mb-3 form-floating">
                    <input type="text" className={`form-control ${this.state.usernameEmpty ? 'is-invalid' : ''}`} name="username" placeholder="Username" id="username" onChange={this.handleInputChange} required />
                    <label htmlFor="username" className="form-label">Username</label>
                </div>
                <div className="mb-3 form-floating">
                    <input type="password" className={`form-control ${this.state.passwordEmpty ? 'is-invalid' : ''}`} name="password" placeholder="Password" id="password" onChange={this.handleInputChange} required/>
                    <label htmlFor="password" className="form-label">Password</label>
                </div>
                <div className="mb-3 d-grid">
                    <button type="button" className="ws-button" id="loginBtn" onClick={this.handleLoginButton} disabled={this.state.loading}> {this.state.loading ? <span className="spinner-border text-light"></span> :'Speichern'} </button>
                </div>
                <div className="mb-3">
                    {this.state.usernameEmpty && <span className="form-control"  >Bitte geben Sie einen Benutzernamen ein</span>}
                    {this.state.passwordEmpty && <span className="form-control">Bitte geben Sie ein Passwort ein</span>}
                </div>
            </form>
            </div>
        </div>;
    }
}
export default OneWayPassword;