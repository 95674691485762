import React, { Suspense } from 'react';
import './App.css';
const Sidebar = React.lazy(() => import('./Sidebar/Sidebar.jsx'));
const Home = React.lazy(() => import('./Pages/Home/Home.jsx'));
const Clients = React.lazy(() => import('./Pages/Clients/Clients.jsx'));
import Cookie from './Tools/Cookie';
const Login = React.lazy(() => import('./Pages/Login/Login.jsx'));
const Settings = React.lazy(() => import('./Pages/Settings/Settings.jsx'));
const Keys = React.lazy(() => import('./Pages/Keys/Keys'));
const Client = React.lazy(() => import('./Pages/Client/Client'));
const Logs = React.lazy(() => import('./Pages/LogPage/Logs'));

import PropTypes from 'prop-types';

import { PrivateRoute } from './standardComponents/PrivateRoute/PrivateRoute';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
const Database = React.lazy(() => import('./Pages/Database/Database'));
const WebmodulDatabase = React.lazy(() => import('./Pages/WebmoduleDatabase/WebmodulDatabase'));
const Navbar = React.lazy(() => import('./Navbar/Navbar'));
const Files = React.lazy(() => import('./Pages/Files/Files'));
const SwaggerApi = React.lazy(() => import('./Pages/SwaggerApi/SwaggerApi'));
const Mappings = React.lazy(() => import('./Pages/MappingNodes/MappingNodes'));
const LoadingOverlay = React.lazy(() => import('./standardComponents/LoadingOverlay/LoadingOverlay'));
const ConfirmationModal = React.lazy(() => import('./standardComponents/ConfirmationModal/ConfirmationModal'));
import { setUserName } from './standardComponents/redux/actions';
import tokenService from './_services/token.service';
import { connect } from 'react-redux';
import { getUsername } from './standardComponents/redux/selector';
const ThirdPartyKeys = React.lazy(() => import('./Pages/ThirdPartyKeys/ThirdPartyKeys'));
const OnlineApplicationUrl = React.lazy(() => import('./Pages/OnlineApplicationUrl/OnlineApplicationUrl'))
const clientEmailAddresses = React.lazy(() => import('./Pages/ClientEmailAddress/ClientEmailAddress'))


import  OneWayPassword from './Pages/OneWayPassword/OneWayPassword';
import TemporaryRoute  from './standardComponents/PrivateRoute/TemporaryRoute';


const mapStateToProps = state => {
  const username = getUsername(state);
  return {
    username: username
  };
};
class App extends React.Component {
  constructor(props) {
    super(props);
    this.cookie = new Cookie();

    this.testConnection = this.testConnection.bind(this);

    this.state =
    {
      testConnectionInterval: {},
      databaseConfigured: (this.cookie.getCookie('databaseConfigured') === 'true'),
    };
  }


  componentDidUpdate(prevProps) {
    if (this.cookie.getCookie('databaseConfigured') === '') {
      return <Redirect to={{ pathname: '/login', state: { from: prevProps.location } }} />;
    }
  }

  parseJwt(token) {
    if (!token) { return; }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }


  componentDidMount() {
    try
    {
      this.props.setUserName(tokenService.getUser().user);
    }
    catch(e)
    {
      this.props.setUserName('');
    }

    this.testConnection();
    clearInterval(this.state.testConnectionInterval);
    if (this.cookie.getCookie('datebaseConfigured') !== false) {
      var testConnectionInterval = setInterval(() => {
        this.testConnection();
      }, 10000);
      this.setState({ testConnectionInterval: testConnectionInterval });
    }
  }

  async testConnection() {
    // let apiAccess = new ApiAccess();
    // var returnValue = await apiAccess.sendToApi(Properties.apiHostName +"/configapi/database/connection/state", "");
    // if(returnValue === "timeout")
    // {
    // }
    // else if(returnValue.returnMessages !== undefined)
    // {
    //   if(returnValue.returnMessages[0].status === "OK")
    //   {
    //     let cookie = new Cookie();
    //     cookie.setCookie("databaseConfigured",true, 365);
    //     this.setState({databaseConfigured: true});
    //   }
    //   else
    //   {
    //     let cookie = new Cookie();
    //     cookie.setCookie("databaseConfigured",false, 365);
    //     this.setState({databaseConfigured: false});
    //   }
    // }
  }


  render() {
    return <Router>
      <Suspense fallback={<p>...</p>}>
        <LoadingOverlay />
        <ConfirmationModal />
      </Suspense>
      <div className="mainContainer">
        <Suspense fallback={<p>...</p>}>
          <Navbar />
          <Sidebar username={this.props.username}>
          </Sidebar>
        </Suspense>
        <div className="col-xxl-9 content">
          <Switch>
            <Suspense fallback={<p>...</p>}>
              <PrivateRoute path="/clients" component={Clients}>
              </PrivateRoute>
              <PrivateRoute exact path="/" component={Home} />
              <Route path="/login" >
                {<Login />}
              </Route>
              <TemporaryRoute path="/syncportal/:hash" component={OneWayPassword} />
              <PrivateRoute path="settings/database" component={Database} />
              <PrivateRoute path="/home" component={Home} />
              <PrivateRoute path="/client" component={Client} />
              <PrivateRoute path="/keys" component={Keys} />
              <PrivateRoute path="/thirdPartyKeys" component={ThirdPartyKeys} />
              <PrivateRoute path="/applicationUrl" component={OnlineApplicationUrl} />
              <PrivateRoute path="/clientEmailAddresses" component={clientEmailAddresses} />
              <PrivateRoute path="/databasewebmodule" component={WebmodulDatabase} />
              <PrivateRoute path="/settings" component={Settings} />
              <PrivateRoute path="/logs" component={Logs} />
              <PrivateRoute path="/files" component={Files} />
              <PrivateRoute path="/nodeAPI" component={Mappings} />
              <Route path="/swagger" >
                {<SwaggerApi />}
              </Route>
            </Suspense>
          </Switch>
        </div>
      </div>
    </Router>;
  }
}
App.propTypes = {
  location: PropTypes.object,
  setUserName: PropTypes.func
};


const mapDispatchToProps = {
  setUserName,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
