class ApiAccess 
{
    async get(serveradress)
    {
        let returnData = null;
        let controller = new AbortController();
        setTimeout(() => controller.abort(), 6000);
        const requestOptions = {
            signal: controller.signal
        };
        try
        {
            await fetch(serveradress, requestOptions)
                .then(response => response.json())
                .then(data => {returnData = data;});
                return returnData;
        }
        catch(error)
        {
            if(error.name === 'AbortError')
            {
                return 'timeout';
            }
            else 
            {
                return 'timeout';
            }
        }
    }
    async delete(serveraddress, data)
    {
        let returnData = null;
        let controller = new AbortController();
        setTimeout(() => controller.abort(), 30000);
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            signal: controller.signal
        };
        try
        {
        await fetch(serveraddress, requestOptions)
            .then(response => response.json())
            .then(data => {returnData = data;});
            return returnData;
        }
        catch(error)
        {
            if(error.name === 'AbortError')
            {
                return 'timeout';
            }
            else 
            {
                return 'timeout';
            }
        }
    }
    async put(serveraddress, data)
    {
        let returnData = null;
        let controller = new AbortController();
        setTimeout(() => controller.abort(), 30000);
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            signal: controller.signal
        };
        try
        {
        await fetch(serveraddress, requestOptions)
            .then(response => response.json())
            .then(data => {returnData = data;});
            return returnData;
        }
        catch(error)
        {
            if(error.name === 'AbortError')
            {
                return 'timeout';
            }
            else 
            {
                return 'timeout';
            }
        }
    }
    async sendToApi(serveraddress, data)
    {
        let returnData = null;
        let controller = new AbortController();
        setTimeout(() => controller.abort(), 30000);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            signal: controller.signal
        };
        try
        {
        await fetch(serveraddress, requestOptions)
            .then(response => response.json())
            .then(data => {returnData = data;});
            return returnData;
        }
        catch(error)
        {
            if(error.name === 'AbortError')
            {
                return 'timeout';
            }
            else 
            {
                return 'timeout';
            }
        }
    }
    readFromApi(serveraddress)
    {
        let returnData = null;
        fetch(serveraddress)
            .then(response => response.json())
            .then(data => {returnData = data;});

        return returnData;
    }
}

export default ApiAccess;