import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'popper.js/dist/popper';
import 'bootstrap-icons/font/bootstrap-icons.scss';
import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap/dist/js/bootstrap.bundle';

import { Provider } from 'react-redux';
import store from './store';

const container = document.getElementById('root');


const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Provider store={store}>
  <App />
</Provider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: http://bit.ly/CRA-vitals