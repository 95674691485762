import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import nodes from './Nodes/redux/nodes';
import confirmationModal from './standardComponents/ConfirmationModal/redux/reducer/confirmationModal';
import loadingOverlay from './standardComponents/LoadingOverlay/redux/reducer/index';
import carrierDataModal from './ClientListElement/redux/reducer/carrierDataModal';
import user from './standardComponents/redux/reducer/index';

const mainReducer = combineReducers({
    nodes,
    loadingOverlay,
    confirmationModal,
    user,
    carrierDataModal
});

const store = createStore(mainReducer, composeWithDevTools(applyMiddleware(thunk)));
export default store;