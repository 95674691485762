import authHeader from './auth-header';
import Properties from '../Tools/Properties';
import instance from './api';
class ThirdPartyService {
    getKeys() {
        return instance.get(Properties.apiHostName + '/configapi/thirdParty/keys', { headers: authHeader() });
    }
    getSoftwares() {
        return instance.get(Properties.apiHostName + '/configapi/thirdParty/softwares', { headers: authHeader() });
    }
    newKey(key) {
        return instance.post(Properties.apiHostName + '/configapi/thirdParty/key', key, { headers: authHeader() });
    }
    deleteKey(id) {
        return instance.delete(Properties.apiHostName + '/configapi/thirdParty/key/' + id, { headers: authHeader() });
    }
    routeExists(hash) {
        return instance.get(Properties.apiHostName + '/api/syncportal/exists/' + hash, { headers: authHeader() });
    }
    genLink(key) {
        return instance.post(Properties.apiHostName + '/configapi/thirdParty/temporayLink', key, { headers: authHeader() });
    }
}
export default new ThirdPartyService();
