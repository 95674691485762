import Properties from "../Tools/Properties";
import { createBrowserHistory } from 'history';
import TokenService from "./token.service";
import instance from "./api";
class AuthService {
    login(username, password) {
        return instance
            .post(Properties.apiHostName + "/api/Authentication/login", { username, password })
            .then((response) => {
            if (response.data.token) {
                TokenService.setUser(response.data);
                createBrowserHistory().push("/");
                window.location.reload();
            }
            return response.data;
        });
    }
    oneWay(username, password, hash) {
        return instance
            .post(Properties.apiHostName + "/api/syncportal/" + hash, { username, password });
    }
    logout() {
        localStorage.removeItem("user");
        window.location.reload();
    }
}
export default new AuthService();
