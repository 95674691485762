import { SHOW_CONFIRMATION_MODAL, HIDE_CONFIRMATION_MODAL} from '../actionTypes';

const initalState = {
    modalOpen: false,
    modalProps: {}
};

const confirmationModal = (state = initalState, action) => {
    switch (action.type) {
        case SHOW_CONFIRMATION_MODAL:
            {
                return { modalOpen: true, modalProps: action.payload.modalProps };
            }
        case HIDE_CONFIRMATION_MODAL:
            return { ...state, modalOpen: false };
        default:
            return state;
    }
};

export default confirmationModal;